import { v4 as uuidv4 } from 'uuid';

export default function aboutData() {
    
    return (
        [
            {
                id: uuidv4(),
                title: "WHAT IS BRAZILIAN JIU JITSU",
                text: "Brazilian Jiu-Jitsu (BJJ) is a combative martial art, developed in the 1900’s, that specializes in ground fighting, joint manipulation and chokeholds. BJJ has grown in popularity among sport grappling, but more importantly in self-defence since it does not rely on strength or size to defeat an opponent. A smaller jiu-jitsu practitioner can control and overcome bigger opponents by using leverage, weight distribution and proper technique.",
                image: "justin-about.jpg",
                reverse: false
            },
            {
                id: uuidv4(),
                title: "WHO WE ARE",
                text: "At Team Galo we provide a safe space for individuals to learn new skills, develop healthy habits, and reach a new potential. Jiu-jitsu is for all walks of life and regardless of whether you wish to get in shape, compete in sport or join a welcoming community, we support your journey and welcome you to our club. Team Galo offers a range of classes to support your needs, including kids, fundamentals, and women’s only.",
                image: "team-photo-1.jpg",
                reverse: true,
            },
            {
                id: uuidv4(),
                title: "WHY BRAZILIAN JIU JITSU",
                text: "Training BJJ has a number of health benefits, ranging from overall physical health to improved sleep and mental health. Even more so, the practical applications of BJJ can be life saving, making BJJ a timeless, invaluable skill. Since its conception in the 1900’s BJJ has proven that physically vulnerable people can defend themselves and that with proper training, adversity can be overcome in physically threatening situations.",
                image: "team-photo-3.jpg",
                reverse: false
            },
        ]
    );
}