// Packages
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

// Components
import Button from '../reusables/Button';

// Animations
import { parentAnimation } from '../../data/animationData';
import { pushItemUp } from '../../data/animationData';
import { pushItemLeft } from '../../data/animationData';
import { pushItemDown } from '../../data/animationData';

export default function Hero() {
    return (
        <motion.div className="hero content-wrapper" initial="before" animate="after" variants={parentAnimation}>
            <div className="hero-image-wrapper">
                <StaticImage
                    className="hero-image"
                    src="../../images/images/mikey-about.jpg"
                    placeholder="blurred"
                    alt="Team Galo Jiu Jitsu | Edmonton"
                ></StaticImage>
            </div>
            <div className="hero-image-overlay"></div>
            <div className="hero-text-wrapper">
                <motion.p className='hero-intro-text' variants={pushItemDown}>WELCOME TO TEAM GALO</motion.p>
                <motion.h1 className="hero-main-text" variants={pushItemLeft}>
                    EDMONTON BRAZILIAN JIU JITSU
                </motion.h1>
                <motion.p variants={pushItemUp} className="hero-subtext">
                    <motion.span>DEVELOP</motion.span> Healthy Habits <br/>
                    <motion.span>LEARN</motion.span> Self Defense <br/>
                    <motion.span>MEET</motion.span> New People <br/>
                </motion.p>
                <Button
                    text="FREE TRIAL"
                    link="/contact"
                    bg_color="tart-orange-bg"
                    fg_color="platinum-fg"
                ></Button>
            </div>
        </motion.div>
    );
}
