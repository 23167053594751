// Packages
import React from "react";

// Components
import Layout from '../components/Layout';
import Hero from '../components/index/Hero';
import About from '../components/index/About';
import FooterContact from '../components/reusables/FooterContact';
import HeadMetadata from "../components/HeadMetadata";

export default function Home() {
  	return (
		<Layout>
			<HeadMetadata title="Home"></HeadMetadata>
			<Hero></Hero>
			<About></About>
			<FooterContact
				fg_color="rich-black-fogra"
				bg_color="platinum"
			></FooterContact>
		</Layout>
	);
}
