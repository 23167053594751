// Packages
import React from 'react';

// Components
import InfoSection from '../reusables/InfoSection';

// Data
import aboutData from '../../data/aboutData'

export default function About() {

    const data = aboutData();

    return  (
        <div className="about-section">
            {data.map(i => 
                <InfoSection
                    bg_color="platinum-bg"
                    key={i.id}
                    title={i.title}
                    text={i.text}
                    fg_color="rich-black-fogra-fg"
                    reverse={i.reverse}
                    image={i.image}
                ></InfoSection>
            )}
        </div>
    );
}